<template src="./Template.vue"></template>

<script>
import ReportingTemplateMixin from '@/mixins/ReportingTemplateMixin'

export default {
  name: 'app-report',
  mixins: [ReportingTemplateMixin],
  mounted() {
    let payload = {}
    this.moduleName = this.$route.meta.type
    this.title = this.$route.meta.routeName
    this.color = 'default'

    let endpoint = this.endpoint + '/pdfList/' + this.slug + '/' + this.color;

    console.log('pdf-list mounted: ', this.slug, endpoint )
    payload = {
      endpoint: endpoint
    }
    this.fetchItem(payload)
  }
}
</script>